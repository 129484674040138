<template>
  <v-container v-bind="propsPageMainContainer" data-cy="documents-create-page">
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("folders.createTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'documents-index' }"
              data-cy="button-index"
            >
              <v-icon>$pageIndex</v-icon>
            </v-btn>
          </div>
          <FoldersCreate :folderId="folderId"></FoldersCreate>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FoldersCreate from "../components/FoldersCreate.vue";

export default {
  name: "FoldersCreatePage",
  components: {
    FoldersCreate,
  },
  data: () => ({}),
  created() {},
  computed: {
    // 1) Computed property to set the document id.
    folderId() {
      let folderId = "";
      if (this.$route.params.folderId) {
        folderId = this.$route.params.folderId.toString();
      }
      return folderId;
    },
  },
};
</script>
