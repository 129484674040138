<template>
  <v-sheet v-bind="propsCompSheet" data-cy="folders-create">
    <div class="mb-4">
      <v-form @submit.prevent>
        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.name')"
            v-model="editName"
            :error-messages="editNameErrors"
            @input="$v.editName.$touch()"
            @blur="$v.editName.$touch()"
            type="text"
          >
          </v-text-field>
        </div>
        <div class="formField">
          <v-checkbox
            v-bind="propsFormFields"
            :label="$t('documents.private')"
            v-model="editPrivate"
          ></v-checkbox>
        </div>
        <div class="formField">
          <v-autocomplete
            v-bind="propsFormFields"
            v-model="editOwnerId"
            :items="users"
            item-value="id"
            :label="$t('users.owner')"
          >
            <template v-slot:selection="{ item }">
              <span>{{ item.name }} {{ item.surnames }}</span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.name }} {{ item.surnames }}</v-list-item-title
                >
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
      </v-form>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <v-btn
        v-bind="propsButtonAction"
        :loading="status.loading"
        :disabled="!formReadyForSubmit"
        @click="clickCreate"
        data-cy="button-create"
        >{{ $t("common.create") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "FolderCreate",
  mixins: [ComponentStatus],
  props: {
    folderId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
    editName: "",
    editPrivate: true,
    editOwnerId: null,
  }),
  validations: {
    editName: {
      required,
    },
    editPrivate: {},
  },
  computed: {
    editUserId() {
      return this.$store.getters["auth/getUserId"];
    },
    users() {
      return this.$store.getters["users/readAll"];
    },
    formReadyForSubmit() {
      return this.editName;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      if (!this.$v.editName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
  },
  watch: {
    editUserId: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && this.editOwnerId === null) {
          this.editOwnerId = newValue;
        }
      },
    },
  },
  created() {
    this.$store.dispatch("users/readAll").then((/* result */) => {});
  },
  methods: {
    clickCreate() {
      // if (this.firstFormClick) {
      //   this.firstFormClick = false;
      //   this.$v.$touch();
      // }
      // if (!this.$v.$invalid) {
      //   this.sendCreate();
      // }
      this.sendCreate();
    },
    sendCreate() {
      this.setStatusCreating();
      let payload = {
        name: this.editName,
        path: this.editName,
        private: this.editPrivate,
        ownerId: this.editOwnerId,
      };
      if (this.folderId) {
        payload.parentFolderId = this.folderId;
      }
      this.$store
        .dispatch("folders/create", payload)
        .then((/* result */) => {
          // Removing all errors from server.
          // this.$v.$touch();
          // Setting component status to success.
          this.setStatusCreateSuccess();
          this.$store.commit("status/showSuccess");
          this.$router.push({
            name: "documents-index",
          });
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          // this.$v.$touch();
          // Setting component status to failure.
          this.setStatusCreateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
